import './InfoTooltip.css';
import { useSelector, useDispatch } from 'react-redux';
import { closeInfoTooltip } from '../../redux/slices/infoTooltipSlice';
import { useEffect, useRef } from 'react';

function InfoTooltip() {
  const dispatch = useDispatch();
  const infoTooltipRef = useRef();

  const { isOpen, messageText, isValidData } = useSelector(
    (state) => state.infoTooltip
  );

  const handleCloseInfoTooltip = () => {
    dispatch(closeInfoTooltip());
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.keyCode === 27) {
        handleCloseInfoTooltip();
      }
    };

    const handleClickOverlay = (event) => {
      if (event.target === infoTooltipRef.current) {
        handleCloseInfoTooltip();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
      document.addEventListener('mousedown', handleClickOverlay);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOverlay);
    };
  }, [isOpen]);

  return (
    <div
      className={`popup ${isOpen ? 'popup_is-opened' : ''}`}
      ref={infoTooltipRef}
    >
      <div className="popup__container">
        <button
          className={`popup__close `}
          type="button"
          onClick={handleCloseInfoTooltip}
        />

        <div
          className={`popup__image-info popup__image-info_${isValidData ? 'success' : 'error'
            }`}
        />

        <h2
          style={{ textAlign: 'center', marginBottom: '23px' }}
          className="popup__title"
        >
          {messageText}
        </h2>
      </div>
    </div>
  );
}

export default InfoTooltip;
