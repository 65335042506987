import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moviesApi from '../../utils/MoviesApi';
import { MSG_SERVER_ERR } from '../../utils/constants';

export const fetchAllMovies = createAsyncThunk(
  'allMovies/fetchAllMovies',
  async () => {
    return await moviesApi.getMovies();
  }
);

const allMoviesSlice = createSlice({
  name: 'allMovies',
  initialState: {
    isLoading: false,
    allMoviesList: [],
    searchQuery: '',
    shortMoviesToggle: false,
    error: null,
  },
  reducers: {
    changeShortMoviesToggle: (state, action) => {
      state.shortMoviesToggle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMovies.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllMovies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.allMoviesList = action.payload;
      })
      .addCase(fetchAllMovies.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.payload;
        state.error = MSG_SERVER_ERR;
      });
  },
});

export const allMoviesReducer = allMoviesSlice.reducer;
export const {changeShortMoviesToggle} = allMoviesSlice.actions;
