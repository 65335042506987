import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const closeInfoTooltip = createAsyncThunk(
  'infoTooltip/resetInfoTooltip',
  async (_, { dispatch }) => {
    dispatch(closeInfoTooltipForm());
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
);

const infoTooltipSlice = createSlice({
  name: 'infoTooltip',
  initialState: {
    isOpen: false,
    messageText: '',
    isValidData: false,
  },
  reducers: {
    openInfoTooltip: (state, action) => {
      state.messageText = action.payload.text;
      state.isValidData = action.payload.isValidData;
      state.isOpen = true;
    },

    closeInfoTooltipForm: (state) => {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeInfoTooltip.fulfilled, (state) => {
      state.messageText = '';
      state.isValidData = false;
    });
  },
});

export const infoTooltipReducer = infoTooltipSlice.reducer;
export const { openInfoTooltip, closeInfoTooltipForm } =
  infoTooltipSlice.actions;
