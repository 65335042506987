import author_photo from '../../images/author_photo.jpg';
import { AUTHOR_DESCRIPTION } from '../../utils/texts';
import "./AboutMe.scss";

function AboutMe() {
  return (
    <section className="about-me">
      <div className="about-me__content">
        <h2 className="about-me__title">Автор</h2>
        <div className="about-me__info">
          <div className="about-me__text">
            <h3 className="about-me__name">Михаил</h3>
            <span className="about-me__age">Фронтенд-разработчик, 38 лет</span>
            <p className="about-me__description">
             {AUTHOR_DESCRIPTION}
            </p>
            <a
              href="https://github.com/MikhailSulim"
              className="about-me__github"
            >
              Github
            </a>
          </div>
          <img
            className="about-me__photo"
            alt="Изображение автора"
            src={author_photo}
          />
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
