import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import mainApi from '../../utils/MainApi';
import { MSG_SERVER_ERR } from '../../utils/constants';

export const fetchSavedMovies = createAsyncThunk(
  'savedMovies/fetchSavedMovies',
  async () => {
    return await mainApi.getSavedMovies();
  }
);

const savedMoviesSlice = createSlice({
  name: 'savedMovies',
  initialState: {
    isLoading: false,
    savedMoviesList: [],
    searchQuery: '',
    shortMoviesFilter: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedMovies.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSavedMovies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.savedMoviesList = action.payload;
      })
      .addCase(fetchSavedMovies.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.payload;
        state.error = MSG_SERVER_ERR;
      });
  },
});

export const savedMoviesReducer = savedMoviesSlice.reducer;
