import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PERSIST,
  REGISTER,
  REHYDRATE,
  PURGE,
  PAUSE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { allMoviesReducer } from './slices/allMoviesSlice';
import { savedMoviesReducer } from './slices/savedMoviesSlice';
import { authReducer } from './slices/authSlice';
import { infoTooltipReducer } from './slices/infoTooltipSlice';
import { drawerReducer } from './slices/drawerSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'allMovies', 'savedMovies'],
};

const rootReducer = combineReducers({
  allMovies: allMoviesReducer,
  savedMovies: savedMoviesReducer,
  auth: authReducer,
  infoTooltip: infoTooltipReducer,
  drawer: drawerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
