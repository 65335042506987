import { Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import './Header.css';
import Drawer from '../Drawer/Drawer.tsx';
import Logo from '../Logo/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../redux/slices/drawerSlice';

function Header({ isLogged, onNavigateToSignin, onNavigateToSignup }) {
  

 const dispatch = useDispatch();
 const {isOpen} = useSelector(state=>state.drawer);

  const openMobileMenu = () => {
    dispatch(openDrawer());
  };

  return (
    <header className="header">
      {isOpen && <Drawer />}
      <div className="header__content">
        <Logo />
        {isLogged ? (
          <nav className="header__nav-movies">
            <div className="header__menu-full">
              <Navigation />
              <Link to="/profile" className="header__btn-account">
                Аккаунт
              </Link>
            </div>
            <button
              type="button"
              className="header__menu-mobile"
              onClick={openMobileMenu}
            ></button>
          </nav>
        ) : (
          <nav className="header__nav-auth">
            <button
              type="button"
              className="header__btn-signup"
              onClick={onNavigateToSignup}
            >
              Регистрация
            </button>

            <button
              type="button"
              className="header__btn-signin"
              onClick={onNavigateToSignin}
            >
              Войти
            </button>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
