import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const login = createAsyncThunk('auth/login', async () => {});

export const logup = createAsyncThunk('auth/logup', async () => {});

export const logout = createAsyncThunk('auth/logout', async () => {});

export const editUserData = createAsyncThunk('auth/editUserData', async () => {});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    isLoading: false,
    isValidAuth: false,
    user: {
      name: '',
      email: '',
    },
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {},
});

export const authReducer = authSlice.reducer;
